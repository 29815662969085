@use "../../mix.scss" as *;
.contact_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 90vh;
  background-image: url("../../images/contact.png");
  background-size: cover;
  background-position: center;
  position: relative;
  h1 {
    color: #fff;
    font-size: 40px;
    width: 80%;
    text-align: center;
    position: relative;
    z-index: 2;
    @include breack_down(large) {
      font-size: 25px;
      width: 90%;
    }
  }
  p {
    color: #fff;
    font-size: 20px;
    width: 50%;
    text-align: center;
    position: relative;
    z-index: 2;
    @include breack_down(large) {
      font-size: 18px;
      width: 80%;
    }
  }
  button {
    background-color: var(--btn);
    border: none;
    color: #fff;
    padding: 12px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 1rem;
    box-shadow: 0 10px 20px -10px var(--btn-shadow);
    font-weight: 400;
    position: relative;
    z-index: 2;
    top: 1rem;
  }
  .b_blur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.603);
    z-index: 1;
  }
}
.contact_form {
  background-color: var(--b-back);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 2rem;
  @include breack_down(large) {
    padding: 2rem;
  }
  .contact_form_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text);
    h2 {
      font-size: 36px;
      margin: 0rem 0 1rem 0;
      @include breack_down(large) {
        font-size: 25px;
      }
    }
    p {
      color: var(--g-color);
      width: 50%;
      align-items: center;
      @include breack_down(large) {
        width: 90%;
      }
    }
  }
  form {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .form_div {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      label {
        color: var(--g-color);
      }
      input {
        background-color: var(--g-back);
        border: none;
        padding: 1rem;
        width: 65vw;
        color: var(--text);
        border-radius: 9px;
        @include breack_down(large) {
          width: 80vw;
        }
        &:focus {
          outline: 1px solid var(--btn);
        }
      }
    }
    button {
      background-color: var(--btn);
      border: none;
      color: var(--text);
      padding: 12px 20px;
      border-radius: 20px;
      cursor: pointer;
      box-shadow: 0 10px 20px -10px var(--btn-shadow);
      font-weight: 400;
      width: fit-content;
    }
  }
}

.loading_btn {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 2rem;
  p {
    color: var(--text);
  }
}
.loading {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: progress;
  border-radius: 50%;
  border-top: 5px solid gold;
  border-bottom: 5px solid transparent;
  border-left: 5px solid gold;
  border-right: 5px solid transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
