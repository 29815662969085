@use "../../mix.scss" as *;
.nav_container_active {
  width: 90vw !important;
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px, var(--nav-back) 1px);
  background-size: 4px 4px;
  border: 1px solid var(--nav-border);
}
.nav_container {
  position: fixed;
  z-index: 100;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 1rem;
  width: 80vw;
  left: 50%;
  translate: -50% 0;
  border-radius: 999px;
  @include breack_down(large) {
    width: 100%;
    padding: 0.5rem 1rem;
  }
  .nav_logo {
    width: 10rem;
    border-radius: 10px;
    @include breack_down(large) {
      width: 7rem;
    }
  }
  .nav_links_desk {
    display: flex;
    gap: 1rem;
    @include breack_down(large) {
      display: none !important;
    }
    a {
      text-decoration: none;
      color: var(--text);
    }
    .active {
      color: var(--btn);
    }
    .pending {
      color: var(--text);
    }
  }
  .ham_mob_btns {
    display: flex;
    align-items: center;
    gap: 2rem;
    @include breack_down(large) {
      gap: 1rem;
    }
  }

  .theme_btn {
    background-color: var(--b-back);
    padding: 0.5rem;
    border-radius: 7px;
    width: 2rem;
    height: 2rem;
    display: flex;
    cursor: pointer;
    img {
      transition: 0.4s;
      width: 100%;
      filter: invert(42%) sepia(94%) saturate(1565%) hue-rotate(162deg)
        brightness(96%) contrast(101%);
    }
  }
}
.nav_links_mob_container {
  background-color: var(--g-back);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: -120vh;
  left: 0;
  padding-top: 5rem;
  z-index: 10000;

  .nav_links_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: center;
    @include breack_up(small) {
      display: none !important;
    }
    a {
      text-decoration: none;
      color: var(--text);
    }
    .active {
      color: var(--btn);
    }
    .pending {
      color: var(--text);
    }
  }
}
.opened_ham {
  top: 4.7rem !important;
}
.w_0 {
  width: 0 !important;
}
.three {
  width: 23.8%;
  @include breack_up(small) {
    display: none !important;
  }
}
.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.col:first-of-type {
  margin-left: 0;
}
.line {
  width: 30px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--text);
  display: block;
  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}

.wa {
  position: fixed;
  z-index: 100;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  @include breack_down(large) {
    bottom: 1rem;
    right: 1rem;
  }
  &:hover {
    scale: 1.08;
  }
  img {
    height: 5rem;
    @include breack_down(large) {
      height: 3rem;
    }
  }
}
.side_bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 5px;
  top: 50%;
  translate: 25% -50%;
  z-index: 100;
  img {
    height: 3rem;
    @include breack_down(large) {
      height: 2rem;
    }
    &:hover {
      scale: 1.08;
    }
  }
}
