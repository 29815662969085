@use "../../../mix.scss" as *;
.res_container {
  display: flex;
  padding: 5rem 4rem;
  color: var(--text);
  gap: 1rem;
  align-items: center;
  @include breack_down(large) {
    flex-direction: column;
    padding: 2rem;
  }
  .res_left {
    flex-shrink: 0;
    width: 40vw;
    @include breack_down(large) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  .res_right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h3 {
      color: var(--btn);
    }
    h2 {
      color: var(--text);
      font-size: 36px;
    }
    p {
      color: var(--g-color);
    }
    .res_divs {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include breack_down(large) {
        grid-template-columns: 1fr;
      }
      gap: 1rem;
      .res_div {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  }
}
.choose_conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 4rem;
  gap: 1rem;
  @include breack_down(large) {
    padding: 2rem;
  }
  h2 {
    color: var(--text);
    font-size: 36px;
  }
  p {
    color: var(--g-color);
  }
  .choose_divs {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;

    .choose_divs_f {
      display: flex;
      gap: 2rem;
      @include breack_down(large) {
        flex-direction: column;
      }
    }
    .choose_divs_s {
      display: flex;
      gap: 2rem;
      @include breack_down(large) {
        flex-direction: column;
      }
    }
    .choose_div {
      // width: 50%;
      background-color: var(--b-back);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 2rem 1rem;
      border-radius: 10px;
      text-align: center;
      h3 {
        color: var(--text);
        font-size: 25px;
        margin: 1rem 0;
      }
      p {
        color: var(--g-color);
      }
      img {
        width: 100%;
      }
    }
    .choose_div_1 {
      width: 65%;
      @include breack_down(large) {
        width: 100%;
      }
    }
    .choose_div_2 {
      width: 35%;
      @include breack_down(large) {
        width: 100%;
      }
    }

    .choose_div_3 {
      width: 45%;
      @include breack_down(large) {
        width: 100%;
      }
    }
    .choose_div_4 {
      width: 55%;
      flex-direction: column-reverse;
      position: relative;
      overflow: hidden;
      @include breack_down(large) {
        width: 100%;
      }
      img {
        position: relative;
        top: 2rem;
        left: 3rem;
        // height: 20rem;
        width: fit-content;
        @include breack_down(large) {
          width: 100%;
        }
      }
    }
  }
}
