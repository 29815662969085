@use "../../../mix.scss" as *;
.test_container {
  display: flex;
  flex-direction: column;
  //   align-items: center;
  padding: 5rem 2rem;
  gap: 2rem;
  color: #fff;
  @include breack_down(large) {
    padding: 2rem;
  }
  .test_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
      color: var(--text);
      font-size: 32px;
    }
    h3 {
      font-size: 16px;
      color: var(--btn);
    }
  }
  .test_divs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    .test_div {
      width: 32%;
      background-color: var(--g-back);
      padding: 2rem;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include breack_down(large) {
        width: 100%;
      }
      .test_div_head {
        display: flex;
        gap: 1rem;
        .test_div_info {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          h4 {
            margin-bottom: 0.7rem;
          }
          p {
            color: var(--g-color);
          }
          a {
            text-decoration: none;
            color: var(--text);
            cursor: pointer;
            &:hover {
              color: var(--btn);
            }
          }
        }
        .test_images {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            object-fit: cover;
          }
          .linkedin_logo {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }
}
