@use "../../mix.scss" as *;
.faq_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 4rem;
  align-items: center;
  @include breack_down(large) {
    padding: 5rem 2rem 2rem 2rem;
  }
  .faq_title {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    align-items: center;
    width: 70%;
    text-align: center;
    @include breack_down(large) {
      width: 100%;
    }
    h2 {
      color: var(--text);
      font-size: 36px;
      @include breack_down(large) {
        font-size: 30px;
      }
    }
    p {
      color: var(--g-color);
    }
  }
}
.faq_divs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  h3 {
    color: var(--text);
    @include breack_down(large) {
      width: 75%;
      font-size: 18px;
    }
  }
  .faq_div {
    width: 80%;
    @include breack_down(large) {
      width: 100%;
    }
    .faq_q_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.5rem 0;
      width: 100%;
      @include breack_down(large) {
        gap: 2rem;
      }
    }
  }
}
.q_btn {
  width: 2rem;
  height: 2rem;
  transition: 0.5s;
  filter: invert(61%) sepia(95%) saturate(1708%) hue-rotate(204deg)
    brightness(100%) contrast(92%);
  cursor: pointer;
}
.q_btn_0 {
  width: 0rem;
}
.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: 0.5s;
}
.ans_div {
  min-height: 0;
  color: var(--g-color);
}
.show {
  grid-template-rows: 1fr;
}
