@use "../../../mix.scss" as *;
.pre_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
  .pre_text {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include breack_down(large) {
      width: 90%;
    }
    h3 {
      color: var(--btn);
      font-size: 16px;
      font-weight: 400;
    }
    h2 {
      color: var(--text);
      font-weight: 700;
    }
    p {
      color: var(--g-color);
    }
  }
  .pre_divs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    @include breack_down(large) {
      grid-template-columns: 1fr;
    }
    .pre_div {
      width: 24vw;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      @include breack_down(large) {
        width: 90%;
      }
      .pre_div_text {
        color: var(--g-color);
        .pre_tiv_title {
          color: var(--text);
          font-weight: 500;
        }
      }
    }
  }
}
