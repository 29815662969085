@use "../../mix.scss" as *;

.digital-business-cards-container {
    padding: 20px;
    text-align: center;
  
    h1 {
      color : var(--text);
      font-size: 2rem;
      margin: 80px 0 20px; /* Added top margin to create spacing above the title */
    }
  }
  
  .business-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .business-card {
    text-align: center;
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: translateY(-5px);
    }
  
    img {
      border: 1px solid var(--text);
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      width: 95%;
      object-fit: cover;
      margin-bottom: 10px; 
    }
  
    h3 {
      margin: 10px 0 0; /* Space above the name */
      font-size: 18px;
      color: var(--text);
    }
  }
  