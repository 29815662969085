@use "./themes.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  transition: 0.3s;
}
main {
  background: var(--black);
}
