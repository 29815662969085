@use "../../../mix.scss" as *;
.port_containr {
  display: flex;
  justify-content: center;
  gap: 9vw;
  align-items: center;
  padding: 4rem;
  background-color: var(--g-back);
  @include breack_down(large) {
    flex-direction: column;
    padding: 2rem;
  }

  .port_l {
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include breack_down(large) {
      max-width: 100vw;
    }
    h2 {
      color: var(--text);
      font-size: 24px;
      font-weight: 700;
    }
    p {
      color: var(--g-color);
    }
    button {
      width: fit-content;
      background-color: var(--btn);
      border: none;
      color: var(--text);
      padding: 12px 20px;
      border-radius: 20px;
      cursor: pointer;
      margin-top: 1rem;
      box-shadow: 0 10px 20px -10px var(--btn-shadow);
      font-weight: 400;
    }
  }
  .port_r {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    .port_r_div {
      display: flex;
      justify-content: center;
    }
    @include breack_down(large) {
      grid-template-columns: 1fr 1fr;
    }
    img {
      height: 5rem !important;
      height: fit-content;
      @include breack_down(large) {
        width: 100%;
      }
    }
  }
}
